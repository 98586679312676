<template>
  <Tile
    :title="title"
    :icon="icon"
    class="tile--x-large"
    data-cy="visa-entry-requirements-checklist"
  >
    <p>
      The most up to date government travel advice for your departure and
      arrival destination.
    </p>
    <p>
      Our
      <Link :href="$link.create('/safe-travels')" target="_blank"
        >Safe Travel Hub
      </Link>
      to find out how you can travel safe and with ease.
    </p>
    <p>
      The
      <span class="u-font-weight--bold">Essential Trip Information</span> for
      your trip to find out important details about your destination, such as
      visa and entry requirements, travel insurance, accommodation, joining
      point instructions and what to pack.
    </p>
  </Tile>
</template>

<script lang="ts">
import Vue from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Link from "../Link/Link.vue";

export default Vue.extend({
  name: "VisaEntryRequirementsChecklist",
  components: {
    Tile,
    Link,
  },
  data() {
    return {
      title: "You should also check:",
      icon: "information",
    };
  },
});
</script>
