import type { WithContext, Thing, BreadcrumbList } from "schema-dts";
import { DecoratorOptions } from "../types/Options";
import { Breadcrumb } from "~~/components/PageBreadcrumb/Props";

type SocialUrls = Pick<
  DecoratorOptions,
  "facebookUrl" | "xUrl" | "youtubeUrl"
> & { webSite?: boolean };

const baseJsonLd = (socialUrls: SocialUrls): WithContext<Thing>[] => {
  return [
    {
      "@context": "https://schema.org",
      "@type": socialUrls.webSite ? "WebSite" : "Organization",
      url: "https://www.intrepidtravel.com",
      name: "Intrepid Travel",
      sameAs: [
        socialUrls.facebookUrl || "",
        socialUrls.xUrl || "",
        socialUrls.youtubeUrl || "",
      ],
      logo: "/files/images/logos/intrepid-logo.svg",
    },
  ];
};

export const getJsonLd =
  (socialUrls: SocialUrls) =>
  <T extends Thing>(jsonld?: WithContext<T> | WithContext<T>[]) => {
    const base = baseJsonLd(socialUrls);
    if (jsonld === undefined) return base;
    if (jsonld instanceof Array) return [...base, ...jsonld];
    return [...base, jsonld];
  };

export const breadCrumbJsonLd = (
  items: Breadcrumb["items"],
  domain: string
): WithContext<BreadcrumbList> => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: items.map((item, index) => {
      return {
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@id": `${domain}${item.href}`,
          name: item.title,
        },
      };
    }),
  };
};
