import Vue from "vue";
import { JsonRte } from "../types/Contentstack";

type prefixLocaleCodeToRelativePathsMixinType = {
  methods: {
    prefixLocaleCodeToRelativePaths: (richTextJSONEntry: JsonRte) => JsonRte;
  };
};

export const prefixLocaleCodeToRelativePaths: prefixLocaleCodeToRelativePathsMixinType =
  {
    methods: {
      prefixLocaleCodeToRelativePaths(
        this: Vue & prefixLocaleCodeToRelativePathsMixinType["methods"],
        richTextJSONEntry
      ) {
        if (
          !!Object.keys(richTextJSONEntry).length &&
          richTextJSONEntry.type === "a" &&
          richTextJSONEntry.attrs &&
          richTextJSONEntry.attrs?.url &&
          !richTextJSONEntry.attrs?.url.startsWith(
            `/${this.$i18n.localeProperties.code}/`
          )
        ) {
          richTextJSONEntry.attrs.url = this.$link.create(
            richTextJSONEntry.attrs?.url
          );
        }

        if ("children" in richTextJSONEntry) {
          richTextJSONEntry.children?.forEach((child) =>
            this.prefixLocaleCodeToRelativePaths(child)
          );
        }

        return richTextJSONEntry;
      },
    },
  };
