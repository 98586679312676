<template>
  <Page v-bind="page">
    <div class="u-margin-top--2">
      <ErrorBoundary
        v-if="headline"
        data-cy="visa-entry-requirements-pageHeadline"
      >
        <PageHeadLine v-bind="headline" />
      </ErrorBoundary>
      <ErrorBoundary
        v-if="introduction"
        class="
          l-container l-container--small
          u-margin-bottom--3
          sm:u-margin-bottom--6
          u-margin-top--4
          md:u-margin-top--6
        "
        data-cy="visa-entry-requirements-introduction"
      >
        <Introduction v-bind="introduction" />
      </ErrorBoundary>
      <ErrorBoundary
        class="
          l-container
          u-margin-bottom--3
          u-padding-left--1
          u-padding-right--1
        "
      >
        <client-only>
          <div
            id="sherpa-trip-element"
            class="visa-entry-requirement-page__sherpa-form"
          ></div>
        </client-only>
      </ErrorBoundary>
      <div class="l-container visa-entry-requirement-page__info">
        <VisaEntryRequirementsChecklist />
        <ContactUsTile />
      </div>
    </div>
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { VisaEntryRequirementsPageProps } from "./Props";
import PageHeadLine from "~/components/PageHeadline/PageHeadline.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import Introduction from "~~/components/Introduction/Introduction.vue";
import ContactUsTile from "~~/components/ContactUsTile/ContactUsTile.vue";
import VisaEntryRequirementsChecklist from "~~/components/VisaEntryRequirementsChecklist/VisaEntryRequirementsChecklist.vue";
import Page from "~/components/Page/Page.vue";

export default Vue.extend({
  name: "VisaEntryRequirementsPage",
  components: {
    PageHeadLine,
    ErrorBoundary,
    Introduction,
    ContactUsTile,
    VisaEntryRequirementsChecklist,
    Page,
  },
  props: {
    page: {
      type: Object as PropType<VisaEntryRequirementsPageProps["page"]>,
      required: true,
    },
    headline: {
      type: Object as PropType<VisaEntryRequirementsPageProps["headline"]>,
      required: false,
      default: undefined,
    },
    introduction: {
      type: Object as PropType<VisaEntryRequirementsPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
  },
  created() {
    if (process.client) {
      const sherpaSDK = document.createElement("script");
      sherpaSDK.setAttribute(
        "src",
        `https://sdk.joinsherpa.io/widget.js?appId=${this.$config?.public?.sherpaAppId}`
      );
      sherpaSDK.onload = function buildSherpa() {
        const elementConfig = {
          placement: "discovery",
        };
        // @ts-ignore Fix ts error for $sherpa
        $sherpa.V2.createElement("trip", elementConfig).mount(
          "#sherpa-trip-element"
        );
      };
      document.head.appendChild(sherpaSDK);
    }
  },
});
</script>
<style lang="scss">
@import "./visa-entry-requirements-page";
</style>
