<template>
  <div v-if="tagline || longDescription" data-cy="introduction">
    <p v-if="tagline" class="lead">{{ tagline }}</p>
    <JsonRichText
      v-if="longDescription && longDescriptionType === 'object'"
      v-bind="longDescription"
    />
    <div
      v-if="longDescription && longDescriptionType === 'string'"
      v-html="longDescription"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { IntroductionProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "Introduction",
  components: {
    JsonRichText,
  },
  props: {
    tagline: {
      type: String as PropType<IntroductionProps["tagline"]>,
      required: false,
      default: null,
    },
    longDescription: {
      type: [Object, String] as PropType<IntroductionProps["longDescription"]>,
      required: false,
      default: null,
    },
  },
  computed: {
    longDescriptionType() {
      return typeof this.longDescription;
    },
  },
});
</script>
