<template>
  <Tile
    :title="title"
    :icon="icon"
    class="tile--x-large"
    data-cy="contact-us-tile"
  >
    <p>
      We know planning a trip can be overwhelming, which is why we're here to
      help.
    </p>
    <p>
      You can
      <Link :href="$link.create(`/contact-us`)" target="_blank"
        >contact us</Link
      >
      24 hours 7 days a week.
    </p>
  </Tile>
</template>

<script lang="ts">
import Vue from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Link from "../Link/Link.vue";

export default Vue.extend({
  name: "ContactUsTile",
  components: {
    Tile,
    Link,
  },
  data() {
    return {
      title: "We're here for you",
      icon: "agent-headset",
    };
  },
});
</script>
