import { Store } from "vuex";

export type InitPageParams = {
  locale_iso?: string;
  locale_code?: string;
  locale_domain?: string;
  path?: string;
  slug?: string;
  currency_code?: string;
  current_path?: string;
  page_type?: string;
  live_preview?: string | (string | null)[];
  content_type_uid?: string | (string | null)[];
};

export const initPage = (store: Store<unknown>, params: InitPageParams) => {
  const algoliaPublicSearchKeyDispatcher = store.dispatch(
    "search/getAlgoliaPublicSearchKey"
  );
  const salesRegionDispatcher = store.dispatch(
    "salesRegion/getSalesRegion",
    params.locale_code
  );

  const notificationAndMenuItemsDispatcher = store.dispatch(
    "header/getNotificationAndMenuItems",
    {
      ...params,
      current_path: params.current_path || "<front>",
      page_type: params.page_type,
    }
  );

  return [
    algoliaPublicSearchKeyDispatcher,
    salesRegionDispatcher,
    notificationAndMenuItemsDispatcher,
  ];
};
