<template>
  <LazyHydrate never>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="json" data-cy="json-rich-text" v-html="html" />
  </LazyHydrate>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import LazyHydrate from "vue-lazy-hydration";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import { JsonRichTextProps } from "./Props";
import { prefixLocaleCodeToRelativePaths } from "~~/lib/mixins/prefixLocaleCodeToRelativePaths";

export default Vue.extend({
  name: "JsonRichText",
  components: {
    LazyHydrate,
  },
  mixins: [prefixLocaleCodeToRelativePaths],
  props: {
    json: {
      type: Object as PropType<JsonRichTextProps["json"]>,
      required: false,
      default: undefined,
    },
  },
  computed: {
    html() {
      if (!this.json) {
        return "";
      }
      return jsonToHtml(this.prefixLocaleCodeToRelativePaths(this.json));
    },
  },
});
</script>
