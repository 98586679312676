<template>
  <div v-if="gsaLogoUrl" class="l-container gsa-logo">
    <div class="gsa-logo__image-wrapper">
      <Imagery
        class="gsa-logo__image"
        :src="gsaLogoUrl"
        :alt="gsaLogoAltText"
        backgroundColor="rgba(0 0 0 / 0%)"
        type="static"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "GsaLogo",
  components: {
    Imagery,
  },
  head() {
    return {
      link: this.gsaLogoUrl
        ? [
            {
              rel: "preload",
              as: "image",
              href: this.gsaLogoUrl,
            },
          ]
        : [],
    };
  },
  computed: {
    ...mapGetters("salesRegion", ["gsaLogoUrl", "gsaLogoAltText"]),
  },
});
</script>

<style lang="scss">
@import "./gsa-logo";
</style>
