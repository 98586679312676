<template>
  <div
    v-if="title"
    class="
      u-margin-top--1-5
      u-margin-bottom--1-5
      u-padding-bottom--1-5
      u-padding-top--1-5
      sm:u-text-align--center
      u-background-color--sand
    "
    data-cy="page-headline"
  >
    <h2
      class="l-container headline--4 u-margin-top--0 u-margin-bottom--0"
      data-cy="page-headline--title"
    >
      {{ title }}
    </h2>
    <p
      v-if="description"
      class="l-container lead u-margin-bottom--0"
      data-cy="page-headline--description"
    >
      {{ description }}
    </p>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { PageHeadlineProps } from "./Props";

export default Vue.extend({
  name: "PageHeadline",
  props: {
    title: {
      type: String as PropType<PageHeadlineProps["title"]>,
      required: true,
    },
    description: {
      type: String as PropType<PageHeadlineProps["description"]>,
      required: false,
      default: "",
    },
  },
});
</script>
