<template>
  <Breadcrumb
    v-if="links && links.length"
    class="l-container u-margin-top--2"
    :description="description"
    :items="links"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Breadcrumb from "atlas/src/components/Breadcrumb/Breadcrumb.vue";
import VueMeta from "vue-meta";
import { Breadcrumb as BreadcrumbType, BreadcrumbItem } from "./Props";
import { breadCrumbJsonLd } from "~/lib/mappers/JsonLdMapper";

export default Vue.extend({
  name: "PageBreadcrumb",
  components: {
    Breadcrumb,
  },
  props: {
    description: {
      type: String as PropType<BreadcrumbType["description"]>,
      required: true,
    },
    items: {
      type: Array as PropType<BreadcrumbType["items"]>,
      required: true,
    },
  },
  head(): VueMeta {
    return {
      script: [
        {
          body: true,
          json: breadCrumbJsonLd(this.links, this.$config.public.baseUrl),
          type: "application/ld+json",
        },
      ],
    };
  },
  computed: {
    links(): BreadcrumbItem[] {
      if (!this.items) {
        return [];
      }

      return this.items.map((item) => ({
        title: item.title,
        href: this.$link.create(item.href),
      }));
    },
  },
});
</script>
